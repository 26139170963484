import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login/index'
// import Dashboard from '@/components/Dashboard/index'
// import Income from '@/components/Income/index'
// import Detail from '@/components/Detail/index'
// import List from '@/components/List/index'
// import Shipping from '@/components/Shipping/index'
// import Return from '@/components/Return/index'
// import Instock from '@/components/Instock/index'
// import InputList from '@/components/InputList/index'
// import set from '@/components/set/index'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: 'report',
  routes: [
    // {
    //   path: '*',
    //   name: 'maintence',
    //   component: () => import('@/maintence.vue')
    // }
    {
      path: '/',
      name: 'home',
      redirect: 'login'
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/dashboard',
      component: () => import('@/components/Dashboard/index'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          redirect: 'income'
        },
        {
          path: 'income',
          name: 'income',
          component: () => import('@/components/Income/index')
        },
        {
          path: 'detail',
          name: 'detail',
          component: () => import('@/components/Detail/index')
        },
        {
          path: 'list',
          name: 'list',
          component: () => import('@/components/SalesData/index')
        },
        {
          path: 'shipping',
          name: 'shipping',
          component: () => import('@/components/Shipping/index')
        },
        {
          path: 'return',
          name: 'return',
          component: () => import('@/components/Return/index')
        },
        {
          path: 'instock',
          name: 'instock',
          component: () => import('@/components/Instock/index')
        },
        {
          path: 'momoinstock',
          name: 'momoinstock',
          component: () => import('@/components/MomoInstock/index')
        },
        {
          path: 'pchomeinstock',
          name: 'pchomeinstock',
          component: () => import('@/components/PchomeInstock/index')
        },
        {
          path: 'yahooinstock',
          name: 'yahooinstock',
          component: () => import('@/components/YahooInstock/index')
        },
        {
          path: 'mallstoreshipping',
          name: 'mallstoreshipping',
          component: () => import('@/components/MallStoreShipping/index')
        },
        {
          path: 'inputlist',
          name: 'inputList',
          component: () => import('@/components/InputList/index')
        },
        {
          path: 'set',
          name: 'set',
          component: () => import('@/components/set/index')
        },
        // {
        //   path: 'CCstatus',
        //   name: 'CCstatus',
        //   component: () => import('@/components/CCstatus/index')
        // },
        // {
        //   path: 'CCList',
        //   name: 'CCList',
        //   component: () => import('@/components/CCList/index')
        // },
        // {
        //   path: 'Dispatch',
        //   name: 'Dispatch',
        //   component: () => import('@/components/Dispatch/index')
        // },
        // {
        //   path: 'InventoryInfo',
        //   name: 'InventoryInfo',
        //   component: () => import('@/components/InventoryInfo/index')
        // },
        {
          path: 'InventoryDetail',
          name: 'InventoryDetail',
          component: () => import('@/components/InventoryDetail/index')
        },
        {
          path: '*',
          component: () => import('@/404.vue'),
          meta: {
            requiresGuest: true
          }
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/404.vue'),
      meta: {
        requiresGuest: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // console.log(': from', from)
  // console.log(': to', to)
  if (to.params.t !== undefined) {
    if (to.fullPath.includes('login')) {
      if (from.fullPath.includes('dashboard')) {
        const ac = sessionStorage.getItem('ac')
        const pwd = sessionStorage.getItem('pwd')
        const rem = sessionStorage.getItem('remember')
        sessionStorage.clear()
        sessionStorage.setItem('ac', ac)
        sessionStorage.setItem('pwd', pwd)
        sessionStorage.setItem('remember', rem)
      }
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (sessionStorage.getItem('session_id') === null) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (sessionStorage.getItem('session_id') !== null) {
      next({
        path: '/dashboard'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
